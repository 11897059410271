import {
  CreateUser,
  UserRole,
  userRolesWithoutSystem,
} from '@bofrak-backend/shared';
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import { Loader } from '@bofrak-backend/shared-ui';
import Main from '../components/Main';
import {
  adminAtom,
  selectedStoreAtom,
  userToCreateAtom,
} from '../recoil/atoms';
import { paths } from '../utils/constants';
import { capitalize } from '../utils/functions';
import { IoIosArrowBack } from 'react-icons/io';

function AddUser() {
  const admin = useRecoilValue(adminAtom);
  const navigate = useNavigate();
  const [userToCreate, setUserToCreate] = useRecoilState(userToCreateAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const toast = useToast();

  const { mutateAsync, isLoading } = useMutation(
    (data: CreateUser) => apiAdapter.createUser(data),
    {
      onSuccess: (data) => {
        if (!data) console.log('No data returned from create user mutation');
        setUserToCreate(null);
        navigate(paths.manageUsers);
      },
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: 'Could not create user',
          description: 'Check Inputs',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (!admin) {
      navigate(paths.home);
    }
  }, []);

  if (!userToCreate) return <Loader />;

  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'auto'}
        pb={'2'}
        gap={'2'}>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<IoIosArrowBack />}
          variant="none"
          fontSize={'sm'}
          color={'gray.500'}
          colorScheme="blue">
          Back
        </Button>
        <Heading>Edit User</Heading>

        <Card width={'full'}>
          <CardBody>
            <FormControl>
              <FormLabel fontWeight={'bold'}>Name</FormLabel>
              <Input
                onChange={(e) =>
                  setUserToCreate({ ...userToCreate, name: e.target.value })
                }
                value={userToCreate.name}
                type="text"
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                Phone
              </FormLabel>
              <Input
                value={userToCreate.phone_number}
                type="text"
                onChange={(e) => {
                  setUserToCreate({
                    ...userToCreate,
                    phone_number: e.target.value,
                  });
                }}
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                PIN (optional)
              </FormLabel>
              <Input
                value={userToCreate.PIN}
                type="number"
                placeholder="Enter PIN (4 digits)"
                onChange={(e) => {
                  setUserToCreate({
                    ...userToCreate,
                    PIN: e.target.value,
                  });
                }}
              />
              <FormLabel fontWeight={'bold'} mt={5}>
                Roles
              </FormLabel>
              <CheckboxGroup
                colorScheme="green"
                defaultValue={userToCreate.roles}
                onChange={(roles) => {
                  setUserToCreate({
                    ...userToCreate,
                    roles: roles as UserRole[],
                  });
                }}>
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  {userRolesWithoutSystem.map((role: any) => (
                    <Checkbox key={role} value={role}>
                      {capitalize(role)}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
          </CardBody>
        </Card>
        <HStack w={'full'}>
          <Button
            width={'full'}
            colorScheme={'green'}
            onClick={() => {
              //Validate phone number
              if (userToCreate.phone_number.length < 10) {
                toast({
                  title: 'Invalid phone number',
                  description: 'Please enter a valid phone number',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              //Validate name
              if (userToCreate.name.length < 3) {
                toast({
                  title: 'Invalid name',
                  description: 'Please enter a valid name',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              //Validate PIN
              if (userToCreate.PIN.length !== 4) {
                toast({
                  title: 'Invalid PIN',
                  description: 'Please enter a valid PIN',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              // Validate roles
              if (userToCreate.roles.length === 0) {
                toast({
                  title: 'Invalid roles',
                  description: 'Please select at least one role',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              console.log({ PIN: userToCreate.PIN });

              setUserToCreate({
                ...userToCreate,
                stores: [currentStore?.id as string],
                username: userToCreate.phone_number,
                email: `shopnsmile+${userToCreate.phone_number}@protonmail.com`,
              });

              mutateAsync({ ...userToCreate });
            }}
            isLoading={isLoading}>
            Save
          </Button>

          <Button
            width={'full'}
            colorScheme={'blue'}
            onClick={() => navigate(paths.manageUsers)}>
            Back
          </Button>
        </HStack>
      </Stack>
    </Main>
  );
}

export default AddUser;
