import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Box,
  Text,
  Center,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateUser } from '@bofrak-backend/shared';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import { merchantAtom, selectedStoreAtom } from '../recoil/atoms';
import { isAxiosError } from 'axios';

interface NewEmployeeProps {
  onClose: () => void;
  storeId?: string | undefined;
}

interface FormValues {
  name: string;
  birthday: string;
  phone: string;
  password: string;
  email: string;
}

const NewEmployee = ({ onClose }: NewEmployeeProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>();
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const queryClinet = useQueryClient();
  const toast = useToast();

  const { data: roles } = useQuery({
    queryKey: ['roles', merchant?.id],
    queryFn: () => apiAdapter.getRoles(merchant?.id ?? ' ', 1000),
  });

  const {
    handleSubmit,
    register,
    // control,
    formState: { errors },
  } = useForm<FormValues>();

  const { isLoading, mutateAsync } = useMutation(
    'createEmployee',
    (data: CreateUser) => apiAdapter.createUser(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Employee created',
            description: 'Employee has been created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          // looking to reference the correct store id...
          // will modify this later...
          queryClinet.invalidateQueries(`get-users`);
        }
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          toast({
            title: 'Error',
            description: error.response?.data.message,
            status: 'error',
            duration: null,
            isClosable: true,
          });
        }
      },
    },
  );

  const onSubmit = async (data: FormValues) => {
    const rolesToAdd: string[] = [];
    if (selectedRole) {
      rolesToAdd.push(selectedRole);
    }
    const dataObj = {
      name: data.name,
      username: data.name.toLowerCase(),
      phone_number: data.phone,
      email: data.email,
      roles: rolesToAdd,
      PIN: data.password,
      stores: [currentStore?.id as string],
      merchant_id: merchant?.id as string,
    };
    const employee = await mutateAsync(dataObj);
    const cognitoId = employee.cognito_id;
    if (cognitoId && selectedRole && merchant) {
      await apiAdapter.createPrincipal(cognitoId, merchant.id);
      await apiAdapter.assignRole(cognitoId, selectedRole);
    }
    onClose();
  };

  return (
    <Flex align="center" justify="center" width={'100%'}>
      <Box maxW="100%" w="full" bg="white">
        <Heading size="md" color={'gray.700'} textAlign="center" my={4}>
          Add New Employee
        </Heading>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: '100%',
          }}>
          <Stack spacing={4} width={'100%'}>
            <FormControl id="name" isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Name"
                {...register('name', { required: 'Name is required' })}
              />
              {errors.name && (
                <Text color="red.500" fontSize="sm">
                  {errors.name.message}
                </Text>
              )}
            </FormControl>
            {/* // email input */}

            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="email"
                placeholder="Email"
                {...register('email')}
              />
              {/* {errors.email && <Text color="red.500" fontSize="sm">{errors.email.message}</Text>} */}
            </FormControl>

            <FormControl id="birthday">
              <FormLabel>Birthday</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="date"
                placeholder="Birthday "
                {...register('birthday')}
              />
              {/* {errors.birthday && <Text color="red.500" fontSize="sm">{errors.birthday.message}</Text>} */}
            </FormControl>

            <FormControl id="phone" isInvalid={!!errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Phone Number of Contact Person"
                {...register(
                  'phone',
                  // required in format eg.. +263777777777
                  {
                    required: 'Phone Number of Contact Person is required',
                    pattern: {
                      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                      message:
                        'Invalid phone number, format must be +XXXXXXXXX',
                    },
                  },
                )}
              />
              {errors.phone && (
                <Text color="red.500" fontSize="sm">
                  {errors.phone.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="password" isInvalid={!!errors.password}>
              <FormLabel>Pin</FormLabel>
              <InputGroup>
                <Input
                  _focus={{
                    boxShadow: 'none',
                    border: '2',
                    borderColor: 'blue.200',
                  }}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  {...register('password', {
                    required: 'Password is required',
                  })}
                />
                <InputRightElement h="full">
                  <Button
                    variant="ghost"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && (
                <Text color="red.500" fontSize="sm">
                  {errors.password.message}
                </Text>
              )}
            </FormControl>
            <Center>
              <Text color={'gray.500'} fontWeight={'bold'}>
                Role(s)
              </Text>
            </Center>
            <RadioGroup onChange={setSelectedRole}>
              {roles?.roles.map((role) => (
                <Flex
                  key={role.description}
                  justify={'space-between'}
                  bg={'gray.50'}
                  p={2}
                  align={'center'}>
                  <Text color={'gray.600'} fontSize={'sm'}>
                    {role.description}
                  </Text>
                  <Radio value={role.id}></Radio>
                </Flex>
              ))}
            </RadioGroup>

            <Button
              isLoading={isLoading}
              colorScheme="blue"
              w="full"
              type="submit">
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default NewEmployee;
