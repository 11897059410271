import { Action, Policy } from '@bofrak-backend/shared';
import { apiAdapter, Loader } from '@bofrak-backend/shared-ui';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { merchantAtom } from '../recoil/atoms';
import { getAllServices } from '../utils/functions';

interface Context {
  actionsToAdd?: string[];
  setActionsToAdd?: Dispatch<SetStateAction<string[]>>;
}

const MyContext = createContext<Context>({});

const AppsCard = ({ actionsInApps }: { actionsInApps: string[] }) => {
  const [actions, setActions] = useState(new Set<Action>());

  const queries = useQueries(
    actionsInApps.map((id) => {
      return {
        queryKey: ['get-action', id],
        queryFn: () => apiAdapter.getAction(id),
        onSuccess: (data: Action) => {
          setActions((prev) => new Set([...prev, data]));
        },
      };
    }),
  );

  const { actionsToAdd, setActionsToAdd } = useContext(MyContext);

  return (
    <Box bg="orange.200" p={4} borderRadius="md">
      <Text textAlign="center" fontWeight="bold">
        App Permissions
      </Text>
      <Flex justifyContent="center">
        <Divider borderColor="black" w="50%" />
      </Flex>
      <Stack>
        {Array.from(actions).map((action) => (
          <Checkbox
            isChecked={actionsToAdd?.includes(action.id)}
            onChange={(e) => {
              if (e.target.checked && !actionsToAdd?.includes(action.id)) {
                setActionsToAdd?.((prev) => [...prev, action.id]);
              } else if (!e.target.checked) {
                setActionsToAdd?.(
                  [...(actionsToAdd ?? [])].filter((id) => id !== action.id),
                );
              }
            }}
            fontWeight="bold"
            borderColor="gray.500">
            {action.description}
          </Checkbox>
        ))}
      </Stack>
    </Box>
  );
};

const ActionsList = ({
  actions,
  actionsToAdd,
  setActionsToAdd,
  serviceName,
}: {
  actions?: string[];
  actionsToAdd: string[];
  setActionsToAdd: Dispatch<SetStateAction<string[]>>;
  serviceName: string;
}) => {
  const { data: fetchedActions } = useQuery({
    queryKey: ['get-actions', serviceName],
    queryFn: () =>
      Promise.all(actions?.map((action) => apiAdapter.getAction(action)) ?? []),
  });

  return (
    <Stack>
      {fetchedActions?.map((action, i) => (
        <Box>
          <Checkbox
            key={action.id}
            borderColor="gray.500"
            isChecked={actionsToAdd.includes(action.id)}
            onChange={(e) => {
              const checked = e.target.checked;
              const includes = actionsToAdd.includes(action.id);
              if (checked && !includes) {
                setActionsToAdd((prev) => [...prev, action.id]);
              } else if (!checked) {
                setActionsToAdd((prev) =>
                  [...prev].filter((id) => id !== action.id),
                );
              }
            }}>
            {action.description}
          </Checkbox>
          {i !== fetchedActions?.length && (
            <Divider borderColor="black" mt={2} />
          )}
        </Box>
      ))}
    </Stack>
  );
};

const EditRole = ({
  onClose,
  roleId,
}: {
  onClose: () => void;
  roleId: string;
}) => {
  const merchant = useRecoilValue(merchantAtom);

  const [roleName, setRoleName] = useState<string>('');
  const [actionsToAdd, setActionsToAdd] = useState<string[]>([]);
  const [actionsInRole, setActionsInRole] = useState<string[]>([]);

  const { data: role, isLoading } = useQuery({
    queryKey: ['get-role', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getRole(roleId),
    onSuccess: (data) => {
      setRoleName(data.description);
      const actions: string[] = [];
      for (let policy of data.policies) {
        actions.push(JSON.parse(policy).action_id);
      }
      setActionsToAdd(actions);
      setActionsInRole(actions);
    },
  });

  const { data: allActions } = useQuery({
    queryKey: ['get-actions', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getActions(1000),
  });

  const resourcesQueries = useQueries(
    getAllServices().map((service) => {
      return {
        queryKey: ['get-actions', service, { merchant_id: merchant?.id }],
        queryFn: () => apiAdapter.getResourcesInApp(service, 1000),
      };
    }),
  );

  const { data: allResources } = useQuery({
    queryKey: ['get-resources', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getResources(1000),
  });

  const actionsInServices = useMemo(() => {
    const getPrefix = (path: string) => {
      const split = path.split('/');
      if (split.length === 0) return '';

      return split[1];
    };

    const result: Record<string, string[]> = {};

    for (let query of resourcesQueries) {
      if (query.isSuccess) {
        const resources = query.data.resources;
        const resourceIds = resources.map(({ id }) => id);
        if (resources.length === 0) continue;
        const serviceName = getPrefix(resources[0].path);
        const actions =
          allActions?.actions
            .filter((action) => resourceIds.includes(action.resource_id))
            .map(({ id }) => id) ?? [];

        const oldActions = result[serviceName] ?? [];
        result[serviceName] = [...oldActions, ...actions];
      }
    }
    return result;
  }, [allActions, resourcesQueries]);

  const actionsInApps = useMemo(() => {
    const getAppName = (path: string) => path.split('.')[0];

    const results: Record<string, string> = {};

    const resources = (allResources?.resources ?? []).filter((resource) =>
      resource.path.includes('shopnsmile'),
    );
    const resourceIds = resources.map(({ id }) => id);

    const actions = allActions?.actions ?? [];
    return actions
      .filter((action) => resourceIds.includes(action.resource_id))
      .map(({ id }) => id);
  }, [allResources, allActions]);

  const toast = useToast();
  const client = useQueryClient();

  const { isLoading: isDeletingRole, mutateAsync: deleteRole } = useMutation(
    'delete-role',
    () => apiAdapter.deleteRole(roleId),
    {
      onSuccess: () => {
        toast({
          title: 'Success!',
          description: 'Deleted role!',
          status: 'success',
        });
        client.invalidateQueries('get-roles');
        onClose();
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          toast({
            title: 'Error',
            description: error.response?.data.message,
            status: 'error',
          });
        }
      },
    },
  );

  const [showActions, setShowActions] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateRole = async (e: any) => {
    e.preventDefault();
    if (!merchant) return;

    try {
      setIsUpdating(true);
      const newActions = actionsToAdd.filter(
        (action) => !actionsInRole.includes(action),
      );

      const actionsToRemove = actionsInRole.filter(
        (action) => !actionsToAdd.includes(action),
      );

      const policiesInRole =
        role?.policies.map((policy) => JSON.parse(policy) as Policy) ?? [];

      const policiesToRemove = policiesInRole.filter((policy) =>
        actionsToRemove.includes(policy.action_id),
      );
      await apiAdapter.removePoliciesFromRole(
        roleId,
        policiesToRemove.map(({ id }) => id),
      );

      await Promise.all(
        policiesToRemove.map(({ id }) => apiAdapter.deletePolicy(id)),
      );

      const fetchedActions = await Promise.all(
        newActions.map((id) => apiAdapter.getAction(id)),
      );
      const newPolicies = await Promise.all(
        fetchedActions.map((action) =>
          apiAdapter.createPolicy(
            action.description,
            merchant?.id,
            action.id,
            action.resource_id,
          ),
        ),
      );
      await apiAdapter.addPoliciesToRole(
        roleId,
        newPolicies.map(({ id }) => id),
      );
      await apiAdapter.updateRoleDescription(roleId, roleName);
    } catch (error) {}
    toast({
      title: 'Success',
      description: 'Role updated',
      status: 'success',
    });
    client.invalidateQueries('get-roles');
    onClose();
    setIsUpdating(false);
  };

  const onDeleteRole = () => {
    deleteRole();
  };

  if (isLoading) return <Loader />;

  return (
    <MyContext.Provider
      value={{
        actionsToAdd,
        setActionsToAdd,
      }}>
      <Box mt={8}>
        <form onSubmit={handleUpdateRole}>
          <Stack>
            <FormControl isRequired>
              {' '}
              <Input
                placeholder="Role Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}></Input>
            </FormControl>

            <AppsCard actionsInApps={actionsInApps} />

            <Checkbox
              onChange={(e) => setShowActions(e.target.checked)}
              isChecked={showActions}
              bg="blue.200"
              textAlign="center"
              p={1}
              borderRadius="md"
              boxShadow="md"
              borderColor="gray.500"
              fontWeight="bold">
              Show actions
            </Checkbox>

            {showActions && (
              <Box>
                <Text fontWeight="bold" fontSize="lg" textAlign="center">
                  Actions
                </Text>
                <Accordion
                  allowToggle
                  sx={{ '& > div': { border: '0' } }}
                  bg="blue.50"
                  borderRadius="md">
                  {getAllServices().map((serviceName: string, id: number) => (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontWeight="bold">
                            {serviceName}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        bg="orange.300"
                        borderRadius="md"
                        fontWeight="bold"
                        mt={4}>
                        <ActionsList
                          actions={actionsInServices[serviceName]}
                          actionsToAdd={actionsToAdd}
                          setActionsToAdd={setActionsToAdd}
                          serviceName={serviceName}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Box>
            )}

            <Flex justifyContent="right" gap={2}>
              <Button
                colorScheme="red"
                onClick={onDeleteRole}
                isLoading={isDeletingRole}>
                Delete Role
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isUpdating}>
                Update Role
              </Button>
            </Flex>
          </Stack>
        </form>
      </Box>
    </MyContext.Provider>
  );
};

export default EditRole;
