// Header.tsx

import { Employee, Store } from '@bofrak-backend/shared';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { VscSignIn, VscSignOut } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom'; // <-- IMPORT useHistory
import { MenuTrigger } from './menu-trigger';
import { StoreSwitcher } from '../general/StoreSwitcher';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export const NavBar = ({
  stage,
  merchant,
  user,
  storeId,
  homePath,
  appName,
  merchantId,
  availableStores,
  signOut,
  setCurrentStore,
  onChangeStore,
}: {
  stage: string;
  merchant: string;
  storeId: string;
  user?: Employee;
  homePath: string;
  appName: string;
  merchantId: string;
  availableStores: Store[];
  onChangeStore: (storeId: string) => void;
  signOut: () => Promise<void>;
  setCurrentStore: (value: any) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // Get history for back/forward navigation
  const history = useNavigate();

  return (
    <>
      {/* NAV BAR */}
      <HStack
        as="header"
        position="sticky"
        top={0}
        zIndex={1000}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow="sm"
        py={1}
        px={{ base: 4, md: 8 }}>
        <Flex alignItems="center" gap="3">
          <Box borderRight="1px solid #ddd" paddingRight="2">
            <MenuTrigger
              stage={stage}
              applicationName={merchant}
              storeId={storeId}
              merchantId={merchantId}
            />
          </Box>
          <Text fontWeight="bold" fontSize="xl">
            <Link to={homePath}>{appName}</Link>
          </Text>
        </Flex>

        <Spacer />

        {user ? (
          <HStack spacing={4}>
            {availableStores.length > 1 && storeId && (
              <StoreSwitcher
                availableStores={availableStores}
                currentStoreId={storeId}
                onChangeStore={onChangeStore}
              />
            )}
            <IconButton
              onClick={async () => {
                setIsLoading(true);
                await signOut();
                setCurrentStore(null);
                setIsLoading(false);
              }}
              variant="outline"
              isLoading={isLoading}
              colorScheme="blue"
              aria-label="Log Out"
              borderWidth="0px"
              fontSize="30px"
              icon={<VscSignOut />}
            />
          </HStack>
        ) : (
          <IconButton
            onClick={() => {
              // Redirect to auth app
              window.location.href = `https://authenticator.${stage}.shopnsmile.org`;
            }}
            variant="outline"
            colorScheme="blue"
            borderWidth="0px"
            aria-label="Log In"
            fontSize="30px"
            icon={<VscSignIn />}
          />
        )}
      </HStack>

      {/* BACK & FORWARD BUTTONS */}
      <HStack
        py={1}
        width="100%"
        bg={'transparent'}
        justifyContent="space-between"
        px={{ base: 4, md: 8 }}>
        {/* Left (Back) */}
        <IconButton
          aria-label="Go Back"
          icon={<FaChevronLeft size={20} />}
          colorScheme="blue"
          variant="ghost"
          size="xs"
          onClick={() => history(-1)}
        />
        {/* Right (Forward) */}
        <IconButton
          aria-label="Go Forward"
          icon={<FaChevronRight size={20} />}
          colorScheme="blue"
          variant="ghost"
          size="xs"
          onClick={() => history(1)}
        />
      </HStack>
    </>
  );
};
