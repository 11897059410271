import { AuthType, Employee, UpdateUser } from '@bofrak-backend/shared';
import { Loader } from '@bofrak-backend/shared-ui';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../api/backend';
import { merchantAtom, selectedStoreAtom } from '../../recoil/atoms';
import NewEmployee from '../NewEmployee';
import NewRole from '../NewRole';
import ModalComponent from '../resuable/Modal';
import RoleList from '../RoleList';

const EmployeeCard = ({
  employee,
  index,
  usersLength,
  handleEditUser,
}: {
  employee: Employee;
  index: number;
  usersLength: number;
  handleEditUser: (employee: Employee) => void;
}) => {
  const roleId = employee.roles[0];

  const { data: role } = useQuery({
    queryKey: ['role', employee.id],
    queryFn: () => apiAdapter.getRole(roleId),
  });
  return (
    <Flex
      bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
      p={4}
      align="center"
      fontSize="sm"
      borderBottom={index === usersLength - 1 ? 'none' : '1px solid #E2E8F0'}
      my={3}
      onClick={() => handleEditUser(employee)}
      cursor="pointer">
      <Text flex={1} fontWeight="500" fontSize="sm">
        {employee.name}
      </Text>
      <Text flex={1}>{role?.description ?? 'no role'}</Text>
      <Text flex={1} textAlign="right">
        {dayjs(employee?.created_at).format('DD MMM, YYYY')}
      </Text>
    </Flex>
  );
};

const EditEmployeeModal = ({
  employee,
  onClose,
}: {
  employee?: Employee | null;
  onClose: () => void;
}) => {
  const merchant = useRecoilValue(merchantAtom);

  const store = useRecoilValue(selectedStoreAtom);

  const { data: roles } = useQuery({
    queryKey: ['roles', merchant?.id],
    queryFn: () => apiAdapter.getRoles(merchant?.id ?? '', 1000),
  });

  const [selectedRole, setSelectedRole] = useState<string | null>(
    employee?.roles[0] ?? null,
  );
  const [name, setName] = useState(employee?.name ?? '');
  const [pin, setPin] = useState('');
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isLoading, mutateAsync: updateUserMUtation } = useMutation(
    'updateUser',
    // Update
    (data: UpdateUser) => apiAdapter.updateUser(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Employee updated successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          queryClient.invalidateQueries('get-users');
          onClose();
        }
      },
      onError: (error) => {
        toast({
          title: 'An error occurred',
          description: `${error}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleSave = () => {
    if (!employee) return;

    if (!merchant) return;

    if (!store) return;

    const assignRole = async () => {
      const eId = employee.cognito_id as string;
      const merchantId = merchant?.id as string;
      if (selectedRole) {
        const principal = await apiAdapter.getPrincipal(eId);

        if (!principal) {
          await apiAdapter.createPrincipal(eId, merchantId);
        }
        await apiAdapter.assignRole(eId, selectedRole);
      }
    };

    const rolesToAdd = [];
    if (selectedRole) rolesToAdd.push(selectedRole);

    updateUserMUtation({
      id: employee.id,
      ...(name && { name }),
      roles: rolesToAdd,
      add_to_roles: [
        {
          roles: rolesToAdd,
          store_id: store.id,
        },
      ],
      remove_from_roles: [
        {
          roles: employee.roles,
          store_id: store.id,
        },
      ],
      auth_type: AuthType.Merchant,
      ...(pin && { PIN: pin }),
    });

    assignRole();
  };

  return (
    <Box>
      <Center mt={10} width={'full'}>
        <Text color={'gray.700'} fontWeight={'bold'}>
          Edit Employee
        </Text>
      </Center>
      <Flex direction={'column'} p={2} gap={3}>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Pin"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
        />
      </Flex>
      <RadioGroup onChange={setSelectedRole} value={selectedRole as string}>
        <Flex
          direction={'column'}
          p={2}
          gap={3}
          align={'center'}
          width={'full'}>
          <Text>Roles:</Text>
          {roles?.roles.map((role, index) => (
            <Flex
              key={role.description}
              justify={'space-between'}
              bg={'gray.50'}
              p={2}
              align={'start'}
              width={'full'}>
              <Text color={'gray.600'} width={'50%'} fontSize={'sm'}>
                {role.description}
              </Text>
              <Radio key={index} value={role.id}></Radio>
            </Flex>
          ))}
        </Flex>
      </RadioGroup>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          isLoading={isLoading}
          isDisabled={!name || !selectedRole}
          colorScheme="blue"
          onClick={handleSave}>
          Save
        </Button>
      </Flex>
    </Box>
  );
};

const EmployeeList = () => {
  const merchant = useRecoilValue(merchantAtom);
  const selectedStore = useRecoilValue(selectedStoreAtom);
  const { data: users, isLoading } = useQuery({
    queryKey: ['get-users', { merchant_id: merchant?.id }],
    queryFn: () =>
      apiAdapter.getUsers({
        merchant_id: merchant!.id!,
        limit: 250,
        store_id: selectedStore?.id,
      }),
    enabled: !!merchant,
  });
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditUser = (employee: Employee) => {
    setSelectedEmployee(employee);
    onOpen();
  };

  if (isLoading) return <Loader />;

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          Role
        </Text>
        <Text fontWeight="bold" flex={1} textAlign="right">
          Active Since
        </Text>
      </Flex>

      {/* Employee Rows */}
      {users?.employees.map((employee, index) => (
        <EmployeeCard
          employee={employee}
          index={index}
          usersLength={users?.employees.length ?? 0}
          handleEditUser={handleEditUser}
        />
      ))}

      {/* Edit User Modal */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <EditEmployeeModal employee={selectedEmployee} onClose={onClose} />
      </ModalComponent>
    </Box>
  );
};

const EmployeeSettings = () => {
  const navigate = useNavigate();

  const {
    isOpen: isUsersModalOpen,
    onOpen: onUsersModalOpen,
    onClose: onUsersModalClose,
  } = useDisclosure();

  const {
    isOpen: isRolesModalOpen,
    onOpen: onRolesModalOpen,
    onClose: onRolesModalClose,
  } = useDisclosure();

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>

      <Tabs isManual variant="enclosed" width="100%">
        <TabList
          // Make the TabList sticky
          position="sticky"
          top={0}
          zIndex={1}
          bg="gray.50"
          boxShadow="md"
          padding={'0'}>
          <Tab width="50%">Employee List</Tab>
          <Tab width="50%">Roles</Tab>
        </TabList>

        <TabPanels>
          {/* Employee List Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onUsersModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Employee
              </Button>
            </Flex>
            <EmployeeList /> {/* users={employeeList} /> */}
          </TabPanel>

          {/* Roles Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onRolesModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Role
              </Button>
            </Flex>
            <RoleList />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalComponent isOpen={isUsersModalOpen} onClose={onUsersModalClose}>
        <NewEmployee onClose={onUsersModalClose} />
      </ModalComponent>

      <ModalComponent isOpen={isRolesModalOpen} onClose={onRolesModalClose}>
        <NewRole onClose={onRolesModalClose} />
      </ModalComponent>
    </Box>
  );
};

export default EmployeeSettings;
