import { CreateStore, Store, UpdateStore } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { merchantAtom, selectedStoreAtom } from '../../../recoil/atoms';
import { Loader } from '@bofrak-backend/shared-ui';
import ModalComponent from '../../resuable/Modal';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';

interface StoresProps {
  stores: Store[] | undefined;
}

const StoresList = ({ stores }: StoresProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const queryClient = useQueryClient();
  const merchant = useRecoilValue(merchantAtom);
  const toast = useToast();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      address: '',
      city: '',
      contact_person: '',
      contact_person_phone_number: '',
      phone_number: '',
    },
  });

  const handleClickStore = (store: Store) => {
    setSelectedStore(store);
    reset({
      name: store.name,
      address: store.address as string,
      city: store.city,
      contact_person: store.contact_person,
      contact_person_phone_number: store.contact_person_phone_number,
      phone_number: store.phone_number,
    });
    onOpen();
  };

  const { isLoading, mutateAsync } = useMutation(
    '',
    (data: UpdateStore) => apiAdapter.updateStore(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Store updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
        reset();
        onClose();
        queryClient.invalidateQueries('get-stores');
      },

      onError: (err) => {
        toast({
          title: 'Failed to update store',
          description: `${err}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(err);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dtoObject = {
      id: selectedStore?.id,
      merchant_id: merchant?.id,
      ...data,
    };

    try {
      await mutateAsync(dtoObject);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          City
        </Text>
        <Text fontWeight="bold" flex={1}>
          Contact Person
        </Text>
      </Flex>

      {stores?.map((store, index) => (
        <Flex
          key={index}
          bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
          p={4}
          align="center"
          fontSize="sm"
          onClick={() => handleClickStore(store)}
          borderBottom={
            index === stores.length - 1 ? 'none' : '1px solid #E2E8F0'
          }
          my={3}
          cursor="pointer">
          <Text flex={1} fontWeight="500" fontSize="sm">
            {store.name}
          </Text>
          <Text flex={1}>{store.city}</Text>
          <Text flex={1} textAlign="right">
            {store.contact_person}
          </Text>
        </Flex>
      ))}
      {/* Modal for Editing Store */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Store
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter store name" />
            </FormControl>

            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                {...register('address')}
                placeholder="Enter store address"
              />
            </FormControl>

            <FormControl>
              <FormLabel>City</FormLabel>
              <Input {...register('city')} placeholder="Enter store city" />
            </FormControl>

            <FormControl>
              <FormLabel>Contact Person</FormLabel>
              <Input
                {...register('contact_person')}
                placeholder="Enter contact person"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Contact Person Phone Number</FormLabel>
              <Input
                {...register('contact_person_phone_number')}
                placeholder="Enter phone number"
                type="tel"
              />
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const ManageStores = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [filteredStores, setFilteredStores] = useState<Store[] | null>(null);
  const {
    isOpen: isStoreModalOpen,
    onOpen: onStoreModalOpen,
    onClose: onStoreModalClose,
  } = useDisclosure();

  const { data, isLoading } = useQuery(
    ['get-stores', { merchant_id: merchant?.id }],
    () => apiAdapter.getStores(merchant?.id ?? ''),
    {
      enabled: !!merchant?.id,
      keepPreviousData: true,
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      address: '',
      city: '',
      contact_person: '',
      contact_person_phone_number: '',
      phone_number: '',
    },
  });

  const { isLoading: isCreatingStore, mutateAsync } = useMutation(
    'create-store',
    (data: CreateStore) => apiAdapter.createStore(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Store created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
        onStoreModalClose();
        reset();
        queryClient.invalidateQueries('get-stores');
      },

      onError: (err) => {
        toast({
          title: 'Failed to create store',
          description: `${err}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(err);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataObj = {
      name: data?.name as string,
      contact_person: data?.contact_person as string,
      contact_person_phone_number: data?.contact_person_phone_number as string,
      city: data?.city as string,
      phone_number: data?.phone_number as string,
      merchant_id: merchant?.id as string,
    };

    try {
      await mutateAsync(dataObj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data?.stores) {
      const filteredStoresData = data?.stores?.filter(
        (p: Store) => p.id === currentStore?.id,
      );
      setFilteredStores(filteredStoresData);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign={'center'} fontWeight="bold">
        Manage Stores
      </Text>
      <Flex justify={'flex-end'}>
        <Button size="sm" mb={4} onClick={onStoreModalOpen} colorScheme="blue">
          + Store
        </Button>
      </Flex>
      {filteredStores && filteredStores?.length > 0 ? (
        <StoresList stores={filteredStores} />
      ) : (
        <Center mt={4}>
          <Text>No stores found</Text>
        </Center>
      )}

      <ModalComponent isOpen={isStoreModalOpen} onClose={onStoreModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Store
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', { required: 'This field is required' })}
                placeholder="Enter store name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                {...register('address', { required: 'This field is required' })}
                placeholder="Enter address"
                type="text"
              />
              {errors.address && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                {...register('city', { required: 'This field is required' })}
                placeholder="Enter city"
                type="text"
              />
              {errors.city && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Contact person</FormLabel>
              <Input
                {...register('contact_person', {
                  required: 'This field is required',
                })}
                placeholder="Enter contact person's name"
                type="text"
              />
              {errors.contact_person && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl
              id="contact_person_phone_number"
              isInvalid={!!errors.contact_person_phone_number}>
              <FormLabel>Contact person Phone Number</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Phone Number of Contact Person"
                {...register(
                  'contact_person_phone_number',
                  // required in format eg.. +263777777777
                  {
                    required: 'Phone Number of Contact Person is required',
                    pattern: {
                      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                      message:
                        'Invalid phone number, format must be +XXXXXXXXX',
                    },
                  },
                )}
              />
              {errors.contact_person_phone_number && (
                <Text color="red.500" fontSize="sm">
                  {errors.contact_person_phone_number.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="phone_number" isInvalid={!!errors.phone_number}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Phone Number  Person"
                {...register(
                  'phone_number',
                  // required in format eg.. +263777777777
                  {
                    required: 'Phone Number  is required',
                    pattern: {
                      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                      message:
                        'Invalid phone number, format must be +XXXXXXXXX',
                    },
                  },
                )}
              />
              {errors.phone_number && (
                <Text color="red.500" fontSize="sm">
                  {errors.phone_number.message}
                </Text>
              )}
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button
                isLoading={isCreatingStore}
                type="submit"
                colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default ManageStores;
