export const paths = {
  home: '/',
  settings: '/settings',
  manageUsers: '/manage-users',
  editUser: '/edit-user',
  createUser: '/create-user',
  addUser: '/add-user',
  notFound: '/not-found',
  posDevices: '/pos-devices',
  stores: '/stores',
  addPosDevice: '/add-pos-device',
  editPosDevice: '/edit-pos-device',
  addStore: '/add-store',
  editStore: '/edit-store',
  taxes: '/manage-taxes',
  discounts: '/manage-discounts',
  paymentTypes: '/manage-payment-types',
  loyalties: '/loyalties',
  receipts: '/receipts',
};

export const envVars = {
  CLOUDFRONT_PHOTO_URL: import.meta.env.VITE_CLOUDFRONT_PHOTO_URL,
  S3_BUCKET_FOLDER_NAME: import.meta.env.VITE_S3_BUCKET_FOLDER_NAME,
  ADMIN_PASSWORD: import.meta.env.VITE_ADMIN_PASSWORD,
  API_URL: import.meta.env.VITE_BASE_URL,
  LOYVERSE_STORE: import.meta.env.VITE_LOYVERSE_STORE,
  STAGE: import.meta.env.VITE_STAGE,
  MERCHANT_NAME: import.meta.env.VITE_MERCHANT_NAME,
  REGION: import.meta.env.VITE_REGION,
  COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  USER_POOL_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  COGNITO_IDENTITY_POOL_ID: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
};

export const CLOUDFRONT_PHOTO_URL = envVars.CLOUDFRONT_PHOTO_URL;
export const S3_BUCKET_FOLDER_NAME = envVars.S3_BUCKET_FOLDER_NAME;
