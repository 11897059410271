import {
  Alert,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import Main from '../components/Main';
import {
  adminAtom,
  merchantAtom,
  selectedPosDeviceAtom,
} from '../recoil/atoms';
import { paths } from '../utils/constants';
import { Loader } from '@bofrak-backend/shared-ui';
import { UpdatePosDevice } from '@bofrak-backend/shared';

function EditPosDevice() {
  const admin = useRecoilValue(adminAtom);
  const navigate = useNavigate();
  const [selectedPosDevice, setSelectedPosDevice] = useRecoilState(
    selectedPosDeviceAtom,
  );
  const toast = useToast();
  const merchant = useRecoilValue(merchantAtom);

  const { mutateAsync, isLoading } = useMutation(
    (data: UpdatePosDevice) => apiAdapter.updatePOSDevice(data),
    {
      onSuccess: (data) => {
        if (!data)
          console.log('No data returned from update POS device mutation');
        setSelectedPosDevice(null);
        navigate(paths.posDevices);
      },
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: 'Could not update POS device',
          description: error.message ? error.message : 'Check inputs',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (!admin) {
      navigate(paths.home);
    }
  }, []);

  if (!selectedPosDevice)
    return (
      <Alert status="warning">
        No POS device selected, return to previous page and select
      </Alert>
    );

  if (!merchant) return <Loader />;

  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'auto'}
        pb={'2'}
        gap={'2'}>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<IoIosArrowBack />}
          variant="none"
          fontSize={'sm'}
          color={'gray.500'}
          colorScheme="blue">
          Back
        </Button>
        <Heading>Edit POS Device</Heading>

        <Card width={'full'}>
          <CardBody>
            <FormControl>
              <FormLabel fontWeight={'bold'}>Device Name</FormLabel>
              <Input
                value={selectedPosDevice.name}
                onChange={(e) =>
                  setSelectedPosDevice({
                    ...selectedPosDevice,
                    name: e.target.value,
                  })
                }
                type="text"
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                Prefix
              </FormLabel>
              <Input
                value={selectedPosDevice.prefix}
                onChange={(e) =>
                  setSelectedPosDevice({
                    ...selectedPosDevice,
                    prefix: e.target.value,
                  })
                }
                type="text"
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                MAC Address (optional)
              </FormLabel>
              <Input
                value={selectedPosDevice.mac_address}
                onChange={(e) =>
                  setSelectedPosDevice({
                    ...selectedPosDevice,
                    mac_address: e.target.value,
                  })
                }
                type="text"
              />

              <HStack mt={5}>
                <FormLabel fontWeight={'bold'} htmlFor="activated" mb="0">
                  Activated?
                </FormLabel>
                <Spacer />
                <Switch
                  id="activated"
                  defaultChecked={selectedPosDevice.activated}
                  onChange={(e) => {
                    setSelectedPosDevice({
                      ...selectedPosDevice,
                      activated: e.target.checked,
                    });
                  }}
                />
              </HStack>

              <HStack mt={5}>
                <Text fontSize={'sm'}>
                  Created {moment(selectedPosDevice.created_at).fromNow()}
                </Text>
                <Spacer />
                <Text textAlign={'right'} fontSize={'sm'}>
                  Last updated {moment(selectedPosDevice.updated_at).fromNow()}
                </Text>
              </HStack>
            </FormControl>
          </CardBody>
        </Card>

        <HStack w={'full'}>
          <Button
            width={'full'}
            colorScheme={'blue'}
            onClick={() => navigate(paths.posDevices)}>
            Back
          </Button>
          <Button
            width={'full'}
            colorScheme={'green'}
            onClick={async () => {
              // Validate device name
              if (selectedPosDevice.name.length < 3) {
                toast({
                  title: 'Invalid Device Name',
                  description: 'Please enter a valid device name',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              // Validate prefix
              if (
                !selectedPosDevice.prefix ||
                selectedPosDevice.prefix.length === 0
              ) {
                toast({
                  title: 'Invalid Prefix',
                  description: 'Please enter a valid prefix',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              const data: UpdatePosDevice = {
                id: selectedPosDevice.id,
                merchant_id: merchant.id,
              };

              if (selectedPosDevice.name !== data.name) {
                data.name = selectedPosDevice.name;
              }

              if (selectedPosDevice.prefix !== data.prefix) {
                data.prefix = selectedPosDevice.prefix;
              }

              await mutateAsync(data);
            }}
            isLoading={isLoading}>
            Save
          </Button>
        </HStack>
      </Stack>
    </Main>
  );
}

export default EditPosDevice;
