import {
  Box,
  Button,
  Flex,
  Center,
  Text,
  useDisclosure,
  VStack,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ModalComponent from '../../resuable/Modal';
import { merchantAtom, selectedStoreAtom } from '../../../recoil/atoms';
import { Loader } from '@bofrak-backend/shared-ui';
import { useNavigate } from 'react-router-dom';
import {
  CreatePaymentType,
  PaymentMethodType,
  PaymentType,
  UpdatePaymentType,
} from '@bofrak-backend/shared';
import { apiAdapter } from '../../../api/backend';

interface PaymentTypesProps {
  paymentTypes: PaymentType[] | undefined;
}

const paymentsTypes = Object.values(PaymentMethodType);

const PaymentTypesList = ({ paymentTypes }: PaymentTypesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType | null>(null);
  const queryClient = useQueryClient();
  const toast = useToast();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      payment_type: '',
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'update-payment-type',
    (data: UpdatePaymentType) => apiAdapter.updatePaymentType(data),
    {
      onSuccess: (data) => {
        if (data) {
          onClose();

          queryClient.invalidateQueries('get-payment-types');
          reset();
          toast({
            title: 'Payment type updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: Error) => {
        toast({
          title: 'Failed to update payment type',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const handleClickPaymentType = (paymentType: PaymentType) => {
    setSelectedPaymentType(paymentType);
    reset({
      name: paymentType.name,
      payment_type: paymentType.payment_type,
    });
    onOpen();
  };

  const onSubmit = async (data: any) => {
    const dtoObject = {
      name: data.name as string,
      payment_type: data.payment_type as PaymentMethodType,
      stores: data.stores
        .split(',')
        .map((store: string) => store.trim()) as string[], // Split by comma and trim spaces
      id: selectedPaymentType?.id as string,
    };
    try {
      await mutateAsync(dtoObject);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          Payment Type
        </Text>
        <Text fontWeight="bold" flex={1}>
          Stores
        </Text>
      </Flex>

      {paymentTypes?.map((paymentType, index) => (
        <Flex
          key={index}
          bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
          p={4}
          align="center"
          fontSize="sm"
          onClick={() => handleClickPaymentType(paymentType)}
          borderBottom={
            index === paymentTypes.length - 1 ? 'none' : '1px solid #E2E8F0'
          }
          my={3}
          cursor="pointer">
          <Text flex={1} fontWeight="500" fontSize="sm">
            {paymentType.name}
          </Text>
          <Text flex={1}>{paymentType.payment_type}</Text>
          <Text flex={1} textAlign="right">
            {currentStore?.name}
          </Text>
        </Flex>
      ))}

      {/* Modal for Editing Payment Type */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Payment Type
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                placeholder="Enter payment type name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Payment Method Type</FormLabel>
              <Select
                {...register('payment_type')}
                placeholder="Select payment method type">
                {paymentsTypes.map((paymentType, index) => (
                  <option key={index} value={paymentType}>
                    {paymentType}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl>
                <FormLabel>Stores</FormLabel>
                <Input {...register('stores')} placeholder="Enter store IDs (comma-separated)" />
              </FormControl> */}

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const PaymentTypesSettings = () => {
  const {
    isOpen: isPaymentModalOpen,
    onOpen: onPaymentModalOpen,
    onClose: onPaymentModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [paymentTypesPage, setPaymentTypesPage] = useState<PaymentType[]>([]);
  const queryClient = useQueryClient();
  // 'CASH' | 'CARD' | 'DEBT' | 'MOBILE_MONEY' | 'CHEQUE' | 'BANK_TRANSFER' | 'COUPON' | 'LOYALTY_POINTS' | 'OTHER'

  const { status } = useQuery(
    ['get-payment-types'],
    () =>
      apiAdapter.getPaymentTypes({
        merchant_id: merchant?.id as string,
        limit: 250,
      }),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data) {
          const payments = data.payment_types.filter((p: PaymentType) =>
            p?.stores?.includes(currentStore?.id as string),
          );
          setPaymentTypesPage(payments as PaymentType[]);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      payment_type: '',
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'create-payment-type',
    (data: CreatePaymentType) => apiAdapter.createPaymentType(data),
    {
      onSuccess: (data) => {
        if (data) {
          onPaymentModalClose();
          queryClient.invalidateQueries('get-payment-types');
          reset();
          toast({
            title: 'Payment type created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: Error) => {
        toast({
          title: 'Failed to create payment type',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataObj = {
      name: data.name as string,
      payment_type: data.payment_type as PaymentMethodType,
      stores: [currentStore?.id as string], // Split by comma and trim spaces
      merchant_id: merchant?.id as string,
    };

    try {
      await mutateAsync(dataObj);
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign={'center'} fontWeight="bold">
        Payment Types
      </Text>
      <Flex justify={'flex-end'}>
        <Button
          size="sm"
          mb={4}
          onClick={onPaymentModalOpen}
          colorScheme="blue">
          + Add Payment Type
        </Button>
      </Flex>
      {paymentTypesPage.length > 0 ? (
        <PaymentTypesList paymentTypes={paymentTypesPage} />
      ) : (
        <Center mt={4}>
          <Text>No payment types found</Text>
        </Center>
      )}

      {/* Modal for Creating Payment Type */}
      <ModalComponent isOpen={isPaymentModalOpen} onClose={onPaymentModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Payment Type
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', { required: 'This field is required' })}
                placeholder="Enter payment type name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Payment Method Type</FormLabel>
              <Select
                {...register('payment_type', {
                  required: 'This field is required',
                })}
                placeholder="Select payment method type">
                {paymentsTypes.map((paymentType, index) => (
                  <option key={index} value={paymentType}>
                    {paymentType}
                  </option>
                ))}
              </Select>
              {errors.payment_type && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            {/*  */}

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default PaymentTypesSettings;
