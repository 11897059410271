import { Employee, PosDevice, UpdatePosDevice } from '@bofrak-backend/shared';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AddPosDevice from '../../../pages/add-pos-device';
import {
  merchantAtom,
  posDevicesAtom,
  selectedStoreAtom,
} from '../../../recoil/atoms';
import { Loader } from '@bofrak-backend/shared-ui';
import ModalComponent from '../../resuable/Modal';

interface PosDeviceProps {
  posDevices: PosDevice[] | undefined;
}

const PosDevicesList = ({ posDevices }: PosDeviceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPos, setSelectedPos] = useState<PosDevice | null>(null);
  const merchant = useRecoilValue(merchantAtom);
  const queryClient = useQueryClient();
  const toast = useToast();

  // useForm hook from react-hook-form
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      name: '',
      prefix: '',
      activated: false,
    },
  });

  // Collect unique current_user_ids
  const userIds = Array.from(
    new Set(posDevices?.map((pos) => pos.current_user_id).filter(Boolean)),
  ) as string[];

  // Fetch user data for each user ID
  const userQueries = useQueries(
    userIds.map((userId) => ({
      queryKey: ['user', userId],
      queryFn: () => apiAdapter.getUser(userId),
      enabled: !!userId,
    })),
  );

  // Map user IDs to user data
  const userMap: { [key: string]: Employee } = {};
  userQueries.forEach((query) => {
    if (query.data) {
      userMap[query.data.id] = query.data;
    }
  });

  const handleClickPos = (pos: PosDevice) => {
    setSelectedPos(pos);
    reset({
      name: pos.name,
      prefix: pos.prefix,
      activated: pos.activated,
    });
    onOpen();
  };

  const { mutateAsync, isLoading } = useMutation(
    (data: UpdatePosDevice) => apiAdapter.updatePOSDevice(data),
    {
      onSuccess: (data) => {
        if (!data)
          console.log('No data returned from update POS device mutation');
        onClose();
        toast({
          title: 'POS device updated',
          description: 'POS device has been updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries('get-pos-devices');
      },
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: 'Could not update POS device',
          description: error.message ? error.message : 'Check inputs',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const onSubmit = async (data: any) => {
    if (!merchant) {
      toast({
        title: 'Merchant not found',
        description: 'Could not find merchant',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!selectedPos) {
      toast({
        title: 'POS device not found',
        description: 'Could not find POS device',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const dtoObject: UpdatePosDevice = {
      name: data.name,
      activated: data.activated,
      prefix: data.prefix,
      merchant_id: merchant?.id,
      id: selectedPos?.id,
    };

    await mutateAsync(dtoObject);
  };

  if (!merchant) return <Loader />;

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text textAlign={'left'} fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text textAlign={'center'} fontWeight="bold" flex={1}>
          Prefix
        </Text>
        <Text textAlign={'center'} fontWeight="bold" flex={1}>
          Current User
        </Text>
        <Text fontWeight="bold" flex={1} textAlign="right">
          Status
        </Text>
      </Flex>

      {/* POS Rows */}
      {posDevices?.map((pos, index) => {
        const currentUser = pos.current_user_id
          ? userMap[pos.current_user_id]
          : null;

        return (
          <Flex
            key={index}
            bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
            p={4}
            align="center"
            fontSize="sm"
            onClick={() => handleClickPos(pos)}
            borderBottom={
              index === posDevices?.length - 1 ? 'none' : '1px solid #E2E8F0'
            }
            my={3}
            cursor="pointer">
            <Text flex={1} fontWeight="500" fontSize="sm" textAlign={'left'}>
              {pos.name}
            </Text>
            <Text flex={1} textAlign={'center'}>
              {pos.prefix}
            </Text>
            <Text flex={1} textAlign={'center'}>
              {currentUser
                ? currentUser.name
                : pos.current_user_id
                  ? 'Loading...'
                  : 'No user'}
            </Text>
            <Text flex={1} textAlign="right">
              {pos.activated ? 'Active' : 'Not active'}
            </Text>
          </Flex>
        );
      })}

      {/* Modal for Editing POS */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width={'full'}>
          <Text color={'gray.700'} fontWeight={'bold'}>
            Update POS
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter name" />
            </FormControl>

            <FormControl>
              <FormLabel>Prefix</FormLabel>
              <Input {...register('prefix')} placeholder="Enter prefix" />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Status</FormLabel>
              <Switch
                {...register('activated')}
                isChecked={watch('activated')} // Watching for the switch's value
              />
            </FormControl>

            <Flex justify="flex-end">
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const POSSettings = () => {
  const navigate = useNavigate();
  const merchant = useRecoilValue(merchantAtom);
  const {
    isOpen: isPosModalOpen,
    onOpen: onPosModalOpen,
    onClose: onPosModalClose,
  } = useDisclosure();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [posDevicesPage, setPosDevicesPage] = useRecoilState(posDevicesAtom);

  const { data, isLoading } = useQuery(
    [
      'get-pos-devices',
      { store_id: currentStore?.id, merchant_id: merchant?.id },
    ],
    () =>
      apiAdapter.getPOSDevices({
        store_id: currentStore?.id,
        merchant_id: merchant?.id ?? '',
        limit: 100,
      }),
    {
      enabled: !!currentStore && !!merchant,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (data?.pos_devices) {
      setPosDevicesPage(data);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>

      <Flex justify={'flex-end'} align="center">
        <Button size="sm" onClick={onPosModalOpen} colorScheme="blue" my={4}>
          + Add POS
        </Button>
      </Flex>

      {posDevicesPage && posDevicesPage?.pos_devices?.length > 0 ? (
        <PosDevicesList posDevices={posDevicesPage?.pos_devices} />
      ) : (
        <Center mt={4}>
          <Text>No POS devices found</Text>
        </Center>
      )}

      <ModalComponent isOpen={isPosModalOpen} onClose={onPosModalClose}>
        <AddPosDevice onClose={onPosModalClose} />
      </ModalComponent>
    </Box>
  );
};

export default POSSettings;
