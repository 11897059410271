import { Box, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { apiAdapter } from '../../utils/api';

interface MenuTriggerProps {
  stage: string;
  accessToken?: string;
  applicationName: string;
  storeId: string;
  showMenu?: boolean;
  encryptedCredentials?: string;
  merchantId: string;
}

export const MenuTrigger: React.FC<MenuTriggerProps> = (props) => {
  const { stage = 'unknown', applicationName, storeId, merchantId } = props;

  const [storeName, setStoreName] = useState('');

  const domain = `shopnsmile.org`;

  // Fetch Store Name from API using axios

  useEffect(() => {
    // Fetch Store Name from API using axios

    const fetchStoreName = async () => {
      const store = await apiAdapter.getStore(storeId, merchantId);

      if (store && store.name) {
        const storeName = store.name;

        const capitalizedStoreName =
          storeName?.charAt(0)?.toUpperCase() +
          storeName?.slice(1)?.toLowerCase();

        setStoreName(capitalizedStoreName);
      } else {
        setStoreName('');
      }
    };

    fetchStoreName();
  }, [storeId]);

  return (
    <Box>
      <VStack
        spacing={0}
        cursor={'pointer'}
        color={stage.includes('prod') ? 'blue' : 'red'}
        onClick={() => {
          // Redirect to the autheticator app

          window.location.href = `https://authenticator.${stage.toLowerCase()}.${domain}`;
        }}>
        <Text fontWeight={'black'} textAlign="center">
          {applicationName?.charAt(0)?.toUpperCase() +
            applicationName?.slice(1)}
        </Text>
        <Text
          textAlign={'center'}
          fontSize={'12px'}
          fontWeight={'black'}
          cursor={'pointer'}>
          {storeName?.toLowerCase()}
        </Text>
      </VStack>
    </Box>
  );
};
