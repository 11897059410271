import {
  Flex,
  Heading,
  Text,
  Stack,
  Button,
  Box,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  useToast,
  Divider,
} from '@chakra-ui/react';

import { getAllServices } from '../utils/functions';
import { useQuery, useQueryClient } from 'react-query';
import { apiAdapter } from '../api/backend';
import { Loader } from '@bofrak-backend/shared-ui';
import {
  Action,
  ActionList,
  Policy,
  Resource,
  ResourceList,
  Role,
} from '@bofrak-backend/shared';
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  actionsAtom,
  checkedActionsAtom,
  merchantAtom,
  rolesAtom,
} from '../recoil/atoms';
import { useMemo, useState } from 'react';

interface NewRoleProps {
  onClose: () => void;
  storeId?: string | undefined;
}

interface ResourcesViewProps {
  serviceName: string;
}

interface ResourceViewProps {
  resource: Resource;
  index: number;
}

interface ActionViewProps {
  action: Action;
}

const getActionsInResource = (actions: Action[], resourceId: string) => {
  const result = actions.filter(
    (action: Action) => action.resource_id == resourceId,
  );
  return result;
};

function ActionView({ action }: ActionViewProps) {
  const [checkedActions, setCheckedActions] =
    useRecoilState(checkedActionsAtom);
  const checkAction = (actionId: string) => {
    // check if the action has not already been checked
    if (!checkedActions.includes(actionId))
      setCheckedActions([...checkedActions, actionId]);
  };

  const unCheckAction = (actionId: string) => {
    setCheckedActions(
      [...checkedActions].filter((id: string) => id !== actionId),
    );
  };

  const onCheckBox = (e: any) => {
    if (e.target.checked) {
      checkAction(action.id);
    } else {
      unCheckAction(action.id);
    }
  };

  return (
    <Flex>
      <Checkbox
        onChange={onCheckBox}
        isChecked={checkedActions.includes(action.id)}
        borderColor="gray.500">
        {action.description}
      </Checkbox>
      <Box></Box>
    </Flex>
  );
}

function ResourceView({ resource, index }: ResourceViewProps) {
  const actionsAll = useRecoilValue(actionsAtom);
  const actions = getActionsInResource(actionsAll, resource.id);

  return (
    <Box mt={2} mb={2}>
      {actions.map((action: Action, key: number) => (
        <ActionView key={key} action={action} />
      ))}
      <Divider borderColor="black" />
    </Box>
  );
}

function ResourcesView({ serviceName }: ResourcesViewProps) {
  const setActions = useSetRecoilState(actionsAtom);

  const { data: resourcesList, isLoading: isResourcesLoading } =
    useQuery<ResourceList>({
      queryKey: `${serviceName}-resources`,
      queryFn: () => apiAdapter.getResourcesInApp(serviceName, 1000),
      onSuccess: async (data) => {
        const actionsLists = await Promise.all(
          data.resources.map((resource: Resource) =>
            apiAdapter.getActionsInResource(resource.id, 100),
          ),
        );
        actionsLists.map((actionsList: ActionList) =>
          actionsList.actions.map((action: Action) =>
            setActions((oldActions) => {
              if (
                !oldActions
                  .map((action: Action) => action.id)
                  .includes(action.id)
              )
                return [...oldActions, action];
              return oldActions;
            }),
          ),
        );
      },
    });

  if (isResourcesLoading) return <Loader />;

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left" fontWeight="bold">
            {serviceName}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel
        bg="orange.300"
        borderRadius="md"
        fontWeight="bold"
        mt={2}>
        {resourcesList?.resources.map((resource: Resource, key: number) => (
          <ResourceView key={key} resource={resource} index={key} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
}

const AppsCard = ({
  checkedActions,
  setCheckedActions,
}: {
  checkedActions: string[];
  setCheckedActions: SetterOrUpdater<string[]>;
}) => {
  const merchant = useRecoilValue(merchantAtom);
  const { data: actionsPage } = useQuery({
    queryKey: ['get-actions', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getActions(1000),
  });

  const actions = useMemo(
    () =>
      actionsPage?.actions.filter((action) =>
        action.description.startsWith('Access'),
      ) ?? [],
    [actionsPage],
  );

  return (
    <Stack p={4} bg="orange.200" borderRadius="md" boxShadow="md">
      <Stack alignItems="center">
        <Text textAlign="center" fontWeight="bold">
          App Permissions
        </Text>
        <Divider borderColor="black" w="50%" />
      </Stack>
      {actions.map((action, i) => (
        <Checkbox
          borderColor="gray.500"
          onChange={(e) => {
            if (e.target.checked && !checkedActions.includes(action.id)) {
              setCheckedActions([...checkedActions, action.id]);
            } else if (!e.target.checked) {
              setCheckedActions(
                [...checkedActions].filter((id) => id != action.id),
              );
            }
          }}
          isChecked={checkedActions.includes(action.id)}
          fontWeight="bold">
          {action.description}
        </Checkbox>
      ))}
    </Stack>
  );
};

const NewRole = ({ onClose }: NewRoleProps) => {
  const [checkedActions, setCheckedActions] =
    useRecoilState(checkedActionsAtom);
  const merchant = useRecoilValue(merchantAtom);
  const actions = useRecoilValue(actionsAtom);
  const [roleName, setRoleName] = useState('');
  const [isCreatingRole, setIsCreatingRole] = useState(false);
  const setRoles = useSetRecoilState(rolesAtom);
  const toast = useToast();

  const [showActions, setShowActions] = useState(true);

  const serviceNames = getAllServices();

  const client = useQueryClient();

  const createRole = async (e: any) => {
    e.preventDefault();

    setIsCreatingRole(true);

    // generate the policies for the actions and resources
    // go through checked actions to do this
    const fetchedActions = await Promise.all(
      checkedActions.map((actionId) => apiAdapter.getAction(actionId)),
    );

    const policyPromises = checkedActions.map((actionId: string) => {
      // get the action by its id
      const action = fetchedActions.find((action) => action.id == actionId);
      if (action) {
        const resourceId = action.resource_id;
        // create the policy
        if (merchant)
          return apiAdapter.createPolicy(
            action.description,
            merchant.id,
            actionId,
            resourceId,
          );
      }
    });
    const policies = (await Promise.all(policyPromises)).map(
      (policy: Policy | undefined) => policy?.id,
    ) as string[];

    // create the role
    if (merchant) {
      const role = await apiAdapter.createRole(roleName, merchant.id);
      await apiAdapter.addPoliciesToRole(role.id, policies);
      setIsCreatingRole(false);
      setCheckedActions([]);
      toast({
        title: 'Role created successfully!',
        description: `Role ${roleName} has been successfully created!`,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      client.invalidateQueries('get-roles');
    }
    onClose();
  };

  return (
    <Flex align="center" justify="center" width={'100%'}>
      <Box maxW="100%" w="full" bg="white">
        <Heading size="md" color={'gray.700'} textAlign="center" my={4}>
          Create Role
        </Heading>
        <form
          onSubmit={createRole}
          style={{
            width: '100%',
          }}>
          <Stack spacing={4} width={'100%'}>
            <Input
              placeholder="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
            />
            <AppsCard
              setCheckedActions={setCheckedActions}
              checkedActions={checkedActions}
            />
            <Checkbox
              onChange={(e) => setShowActions(e.target.checked)}
              isChecked={showActions}
              bg="blue.200"
              textAlign="center"
              p={1}
              borderRadius="md"
              boxShadow="md"
              borderColor="gray.500"
              fontWeight="bold">
              Show actions
            </Checkbox>
            {showActions && (
              <Box>
                <Text fontWeight="bold" fontSize="lg" textAlign="center">
                  Actions
                </Text>
                <Accordion
                  allowToggle
                  sx={{ '& > div': { border: '0' } }}
                  bg="blue.50"
                  borderRadius="md">
                  {serviceNames.map((serviceName: string, id: number) => (
                    <ResourcesView key={id} serviceName={serviceName} />
                  ))}
                </Accordion>
              </Box>
            )}
            <Button
              // isLoading={isLoading}
              colorScheme="blue"
              w="full"
              isLoading={isCreatingRole}
              type="submit">
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default NewRole;
